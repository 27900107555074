import { BottomUpHierarchy } from "@iventis/domain-model/model/bottomUpHierarchy";
import { NodeSortAction } from "@iventis/domain-model/model/nodeSortAction";
import { FieldSort } from "@iventis/types";
import { Node } from "@iventis/domain-model/model/node";
import { ReactNode } from "react";

export interface TreeBrowserNode {
    id: string;
    childNodes: TreeBrowserNode[];
    parentId?: string;
    type: string;
    name: string;
    childCount?: number;
    sourceId?: string;
    lastUpdatedAt?: Date;
    lastViewedAt?: Date;
    // Normally this wouldn't be to the domain node
    // But this is only used for favourites/recently viewed for now
    // Ideally find a way to be generic
    bottomUpHierarchy?: BottomUpHierarchy<Node>;
    favourite?: boolean;
}

export enum TreeBrowserRequestType {
    ADD_NODE = "ADD_NODE",
}

export type PendingTreeBrowserRequest = { requestId: string; type: TreeBrowserRequestType };

export interface TreeBrowserSort<TNode extends TreeBrowserNode> extends FieldSort<NodeSortAction> {
    name: string;
    sort: (nodes: TNode[]) => TNode[];
}

export interface TreeBrowserState<TNodeTree extends TreeBrowserNode, TNode extends UnionOfNodes<TNodeTree> = UnionOfNodes<TNodeTree>> {
    mainNodeId: string;
    treeId: string;
    tree: TNodeTree;
    selectedNodeIds: string[];
    expandedNodeIds: string[];
    loadingNodeIds: string[];
    pendingRequests: PendingTreeBrowserRequest[];
    nodeThumbnails: { [nodeId: string]: string };
    sort: TreeBrowserSort<TNode>;
    isLoadingTree: boolean;
    singleSelectionOnly?: boolean;
    expandAllNodesOnLoad?: boolean;
    isInitialised?: boolean;
    errorLoadingTree?: boolean;
    multiSelectRequiresCtrl?: boolean;
    filteredTree?: TNodeTree;
}

export enum TreeBrowserQueryString {
    MAIN_NODE_ID = "MAIN_NODE_ID",
    EXPANDED_NODE_ID = "OPEN_NODE_ID",
}

export interface NodeServices<TNodeTree extends TreeBrowserNode, TNode extends UnionOfNodes<TNodeTree> = UnionOfNodes<TNodeTree>> {
    getTree: (id: string, sort: TreeBrowserSort<TNodeTree>, search: string) => Promise<TNodeTree>;
    addNode: (node: TNode, tree: TNodeTree) => Promise<TNode>;
    deleteNodes: (nodes: TNode[], tree: TNodeTree) => Promise<string[]>;
    updateNode: (node: TNode, tree: TNodeTree) => Promise<TNode>;
    moveNodes: (nodes: TNode[], tree: TNodeTree) => Promise<void>;
    copyNodes: (nodes: TNode[], destinationId: string, includeMapObjects: boolean, keepPermissions: boolean, tree: TNodeTree) => Promise<void>;
    getNode: (node: string, context: Omit<TreeBrowserState<TNodeTree>, "isLoadingTree">, sort?: TreeBrowserSort<TNode>, ignoreChildren?: boolean) => Promise<TNode>;
    getParents: (id: string) => Promise<BottomUpHierarchy<Node>>;
    getNodeThumbnails?: {
        [key in TNode["type"]]?: (node: TNode, tree?: TNode) => Promise<{ [nodeId: string]: string }>;
    };
    addFavourite?: (nodeId: string) => Promise<void>;
    deleteFavourite?: (nodeId: string) => Promise<void>;
}

/** Union of Nodes in a given tree of nodes (only applies to hierarchical structures). I.e. Given { RootNode: { childNodes: InstanceNode[] } }, then UnionOfNodes<RootNode> = RootNode | InstanceNode */
export type UnionOfNodes<TNode extends TreeBrowserNode> =
    | TNode
    | (TNode extends { childNodes: (infer TChild)[] } ? (TChild extends TNode ? TNode : TChild extends TreeBrowserNode ? UnionOfNodes<TChild> : TNode) : TNode);

export type OverflowOption<TNode extends TreeBrowserNode, TLabel extends string | ReactNode = string> = TLabel extends string
    ? {
          label: TLabel;
          key?: string;
          selected: (node: TNode) => void;
      }
    : {
          label: TLabel;
          key: string;
          selected: (node: TNode) => void;
      };
