/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Asset } from "@iventis/domain-model/model/asset";
import { Node } from "@iventis/domain-model/model/node";
import { NodeSortAction } from "@iventis/domain-model/model/nodeSortAction";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { NodeType } from "@iventis/domain-model/model/nodeType";
import { MapObject } from "@iventis/domain-model/model/mapObject";
import { findNode, NodeServices, TreeBrowserNode, TreeBrowserState, findParentNodeByType } from "@iventis/tree-browser";
import { FieldSort } from "@iventis/types/sorting";
import { parseISO } from "date-fns";
import qs from "qs";
import { Status } from "@iventis/domain-model/model/status";
import { Authorisation } from "@iventis/domain-model/model/authorisation";
import { BottomUpHierarchy } from "@iventis/domain-model/model/bottomUpHierarchy";
import { aggregatePagedResponse, getAssetsByIds } from "@iventis/api-helpers";
import { getAssetSignature } from "@iventis/utilities";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { assetsApi, libraryApi, mappingApi } from "@iventis/api/src/api";
import { getMapObjectNameSystemDatafield } from "@iventis/datafields";
import { MapGroupCreateRequestDto } from "@iventis/domain-model/model/mapGroupCreateRequestDto";
import { translate } from "@iventis/translations/translation";
import { Content } from "@iventis/translations";
import { MapsCopyApiCreateRequest } from "@iventis/domain-model/model/mapsCopyApiCreateRequest";
import { createRequestConfigWithSort, stringInterpolateSort } from "../plan-library/library-sorting";
import { StaticLibraryId } from "../plan-library/mapping-library.helpers";
import { rootStore } from "../state/root.store";

export async function getTree(id: string, sort?: FieldSort<NodeSortAction>) {
    const response = await libraryApi.get<Node>(`/nodes/${id}/tree`, createRequestConfigWithSort(sort));
    const tree = response.data;
    convertNodeTreeDates(tree);
    return tree;
}

export async function getNode<TNode extends TreeBrowserNode>(id: string, _context: TreeBrowserState<TNode>, sort?: FieldSort<NodeSortAction>, ignoreChildren?: boolean) {
    const response = await libraryApi.get<Node>(`/nodes/${id}`, createRequestConfigWithSort(sort));
    const node = response.data;
    convertNodeTreeDates(node);
    return node;
}

export async function getParents(id: string) {
    const response = await libraryApi.get<BottomUpHierarchy<Node>>(`/nodes/${id}/parents`);
    const bottomUpHierarchy = response.data;
    return bottomUpHierarchy;
}

type MapObjectNode = Omit<
    MapObject,
    "level" | "createdByUserId" | "createdByUserName" | "createdAt" | "lastUpdatedByUserId" | "lastUpdatedAt" | "lastUpdatedByUserName" | "sitemapId"
>;

export const convertMapObjectsToNodes = (layerNode: Node, mapObjects: MapObjectNode[], mapObjectNameDataFieldId: string): Node[] =>
    mapObjects.map((mapObject): Node => convertMapObjectToNode({ ...mapObject, layerId: layerNode.id }, mapObjectNameDataFieldId, { [layerNode.id]: layerNode.authorisation }));

/** Converts a map object node into a domain node */
export const convertMapObjectToNode = (
    mapObject: Pick<MapObject, "id" | "layerId" | "dataFieldValues">,
    mapObjectNameDataFieldId: string,
    layerPermissions: Record<string, Authorisation>
): Node => ({
    id: mapObject.id,
    parentId: mapObject.layerId,
    authorisation: layerPermissions[mapObject.layerId],
    childNodes: [],
    childCount: 0,
    name: mapObject.dataFieldValues[mapObjectNameDataFieldId],
    sourceId: mapObject.id,
    status: Status.Active,
    type: NodeType.MapObject,
    createdAt: undefined,
    createdByUserId: undefined,
    createdByUserName: undefined,
    expanded: undefined,
    favourite: false,
    lastUpdatedAt: undefined,
    lastUpdatedByUserId: undefined,
    lastUpdatedByUserName: undefined,
    lastViewedAt: undefined,
});

export async function getSidebarNode(id: string, _context: TreeBrowserState<Node>, sort?: FieldSort<NodeSortAction>, ignoreChildren?: boolean) {
    const node = await getNode(id, _context, sort);
    if (node.type === NodeType.Layer && !ignoreChildren) {
        const mapNode = findNode([_context.tree], _context.mainNodeId);
        const mapId = mapNode?.id;
        const { projectDataFields } = rootStore.getState().mapReducer.mapModule;
        const mapObjectNameId = getMapObjectNameSystemDatafield(projectDataFields)?.id;
        const filter = {
            params: {
                filter: JSON.stringify([{ fieldName: "layerId", operator: IventisFilterOperator.In, value: [node.id] }]),
                sorts: `${mapObjectNameId}Asc`,
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
        };
        const objects = await aggregatePagedResponse<MapObject[]>(mappingApi, `/maps/${mapId}/map_objects/filter`, filter, async (cum, newValue) => (cum ?? []).concat(newValue));
        node.childNodes = convertMapObjectsToNodes(node, objects, mapObjectNameId);
        return { node, objects };
    }
    return { node };
}

async function addNode(node: Node) {
    const { name, type, parentId, id, sourceId } = node;
    const response = await libraryApi.post<Node>(
        `/nodes`,
        { Name: name, Type: type, ParentId: parentId, id, sourceId },
        {
            customErrorMessage: {
                title: translate(Content.errors.custom_network_error.create.title, { entity: translate(Content.library.node_names[node.type]) }),
                message: translate(Content.errors.custom_network_error.create.message, { entity: translate(Content.library.node_names[node.type]) }),
            },
        }
    );
    const newNodes = response.data;
    convertNodeTreeDates(newNodes);
    return newNodes;
}

async function updateNode(node: Node) {
    const response = await libraryApi.put<Node>(`/nodes/${node.id}`, node, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.update.title, { entity: translate(Content.library.node_names[node.type]) }),
            message: translate(Content.errors.custom_network_error.update.message, { entity: translate(Content.library.node_names[node.type]) }),
        },
    });
    response.data.authorisation = node.authorisation; // Retain the users authorisation on the node after update.
    const newNodes = response.data;
    convertNodeTreeDates(newNodes);
    return newNodes;
}

async function moveNodes(nodes: Node[]) {
    await libraryApi.patch<Node[]>(`/nodes`, nodes, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.update.title, { entity: translate(Content.common.items) }),
            message: translate(Content.errors.custom_network_error.update.message, { entity: translate(Content.common.items) }),
        },
    });
}

async function copyNodes(nodes: Node[], destinationId: string, includeMapObjects: boolean, keepPermissions: boolean) {
    // Do mapping copy event
    const request = {
        MapIds: nodes.filter((node) => node.type === NodeType.Map).map((node) => node.id),
        FolderIds: nodes.filter((node) => node.type === NodeType.Folder).map((node) => node.id),
        DestinationFolderId: destinationId,
        IncludeMapObjects: includeMapObjects,
        KeepPermissions: keepPermissions,
    };
    await mappingApi.post<MapsCopyApiCreateRequest>(`/maps/copy`, request, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.update.title, { entity: translate(Content.common.items) }),
            message: translate(Content.errors.custom_network_error.update.message, { entity: translate(Content.common.items) }),
        },
    });
}

async function deleteNodes(nodes: { id: string; type: NodeType }[]) {
    const ids = nodes.map((n) => n.id);
    await libraryApi.delete<string[]>(`/nodes`, {
        params: { ids },
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.delete.title, { entity: translate(Content.common.items) }),
            message: translate(Content.errors.custom_network_error.delete.message, { entity: translate(Content.common.items) }),
        },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    });
    return ids;
}

export async function getLayerThumbnail(node: Node | string) {
    const nodeId = typeof node === "string" ? node : node.id;
    const [layerThumbnailAsset] = (await getAssetsByIds([nodeId], assetsApi)).found;
    const assetUrl = getAssetSignature(layerThumbnailAsset.assetUrl, layerThumbnailAsset.authoritySignature);
    return { [nodeId]: assetUrl };
}

async function getMapThumbnail(node: Node) {
    try {
        const response = await assetsApi.get<Asset>(`/assets/${node.id}/`, { suppressErrors: true });
        return { [node.id]: getAssetSignature(response.data.assetUrl, response.data.authoritySignature) };
    } catch {
        return {};
    }
}

async function addFavourite(nodeId: string) {
    await libraryApi.post(`/favourites/${nodeId}`, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.create.title, { entity: translate(Content.library2.mappingLibraries.add_to_favourites) }),
            message: translate(Content.errors.custom_network_error.create.message, { entity: translate(Content.library2.mappingLibraries.add_to_favourites) }),
        },
    });
}

async function deleteFavourite(nodeId: string) {
    await libraryApi.delete(`/favourites/${nodeId}`, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.delete.title, { entity: translate(Content.library2.mappingLibraries.remove_from_favourites) }),
            message: translate(Content.errors.custom_network_error.delete.message, { entity: translate(Content.library2.mappingLibraries.remove_from_favourites) }),
        },
    });
}

export const libraryNodeServices: NodeServices<Node> = {
    getTree,
    addNode,
    updateNode,
    moveNodes,
    copyNodes,
    deleteNodes,
    getNode,
    getNodeThumbnails: {
        [NodeType.Layer]: getLayerThumbnail,
        [NodeType.Map]: getMapThumbnail,
    },
    addFavourite,
    deleteFavourite,
    getParents,
};

export const sidebarNodeServices: NodeServices<Node> = {
    ...libraryNodeServices,
    getNode: async (id: string, _context: TreeBrowserState<Node>, sort?: FieldSort<NodeSortAction>, ignoreChildren?: boolean) => getNode(id, _context, sort, ignoreChildren),
    getTree: async (id: string, sort?: FieldSort<NodeSortAction>) => {
        const response = await libraryApi.get<Node>(`/nodes/${id}/parents-and-children`, {
            params: {
                expandNodeType: [NodeType.PersonalMappingLibrary, NodeType.MappingLibrary, NodeType.Folder, NodeType.Map],
                ...(sort == null ? {} : { sort: stringInterpolateSort(sort) }),
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
        });
        const tree = response.data;
        convertNodeTreeDates(tree);
        return tree;
    },
    addNode: async (nodeToAdd, tree) => {
        if (nodeToAdd.type === NodeType.Folder) {
            const parentMapId = findParentNodeByType(tree, NodeType.Map, nodeToAdd).id;
            const mapGroup: MapGroupCreateRequestDto = {
                id: nodeToAdd.id,
                name: nodeToAdd.name,
                parentGroupId: nodeToAdd.parentId === parentMapId ? null : nodeToAdd.parentId,
                mapId: parentMapId,
                layerIds: nodeToAdd.childNodes.map((groupNode) => groupNode.id),
            };
            mappingApi.post<MapGroupCreateRequestDto>(`/maps/${parentMapId}/groups`, mapGroup, {
                customErrorMessage: {
                    title: translate(Content.errors.custom_network_error.create.title, { entity: translate(Content.table.group) }),
                    message: translate(Content.errors.custom_network_error.create.message, { entity: translate(Content.table.group) }),
                },
            });
            return nodeToAdd;
        }
        const response = await libraryNodeServices.addNode(nodeToAdd, tree);
        return response;
    },
};

// Get the recently viewed bottom up hierarchy.
async function getRecentlyViewedHierarchy() {
    const response = await libraryApi.get<BottomUpHierarchy<Node>[]>(`/recently-viewed`);
    const bottomUpHierarchy = response.data;
    return bottomUpHierarchy;
}

// Get the favourites bottom up hierarchy.
async function getFavouritesHierarchy() {
    const response = await libraryApi.get<BottomUpHierarchy<Node>[]>(`/favourites`);
    const bottomUpHierarchy = response.data;
    return bottomUpHierarchy;
}

export const recentlyViewedNodeServices: NodeServices<Node> = {
    ...libraryNodeServices,
    getNode: async (id, context, sort) => {
        const node = await getNode(id, context as TreeBrowserState<Node, Node>, sort);
        if (findNode([context.tree], node.parentId) === undefined) {
            node.parentId = StaticLibraryId.RecentlyViewed;
        }
        return node;
    },
    getTree: async (id: string, sort?: FieldSort<NodeSortAction>) => {
        const bottomUpHierarchy = await getRecentlyViewedHierarchy();

        const recentlyViewedNodes = bottomUpHierarchy.map((x) => ({ ...x.item, bottomUpHierarchy: x }));

        recentlyViewedNodes.forEach((node) => convertNodeTreeDates(node));

        // Fake recently viewed library node
        const tree: Node = {
            id: "recently-viewed",
            childNodes: recentlyViewedNodes,
            type: NodeType.MappingLibrary,
            name: "Recently viewed",
            favourite: false,
            sourceId: "",
            authorisation: Authorisation.Admin,
            expanded: false,
            status: Status.Active,
            childCount: recentlyViewedNodes.length,
            createdByUserId: "",
            createdByUserName: "",
            createdAt: new Date(),
            lastUpdatedByUserId: "",
            lastUpdatedByUserName: "",
            lastUpdatedAt: undefined,
        };

        return tree;
    },
    // No add
    addNode: async (_node, _tree) => null,
};

export const favouriteNodeServices: NodeServices<Node> = {
    ...libraryNodeServices,
    // No add
    getNode: async (id, context, sort) => {
        const node = await getNode(id, context as TreeBrowserState<Node, Node>, sort);
        if (findNode([context.tree], node.parentId) === undefined) {
            node.parentId = StaticLibraryId.Favourites;
        }
        return node;
    },
    addNode: async (_node, _tree) => null,
    getTree: async (id: string, sort?: FieldSort<NodeSortAction>) => {
        const bottomUpHierarchy = await getFavouritesHierarchy();

        const favouriteNodes = bottomUpHierarchy.map((x) => ({ ...x.item, bottomUpHierarchy: x }));

        favouriteNodes.forEach((node) => convertNodeTreeDates(node));

        // Fake favourite library node
        const tree: Node = {
            id: "favourites",
            childNodes: favouriteNodes,
            type: NodeType.MappingLibrary,
            name: "Favourites",
            favourite: false,
            sourceId: "",
            authorisation: Authorisation.Admin,
            expanded: false,
            status: Status.Active,
            childCount: favouriteNodes.length,
            createdByUserId: "",
            createdByUserName: "",
            createdAt: new Date(),
            lastUpdatedByUserId: "",
            lastUpdatedByUserName: "",
            lastUpdatedAt: undefined,
        };

        return tree;
    },
};

/**
 * Converts node date strings returned from the API into proper javascript date objects, to be used correctly elsewhere in the application
 */
export const convertNodeTreeDates = (node: Node) => {
    /* eslint-disable no-param-reassign */
    node.createdAt = node.createdAt != null ? parseISO(node.createdAt.toString()) : undefined;
    node.lastUpdatedAt = node.lastUpdatedAt != null ? parseISO(node.lastUpdatedAt.toString()) : undefined;
    node.lastViewedAt = node.lastViewedAt != null ? parseISO(node.lastViewedAt.toString()) : undefined;
    node.childNodes.forEach((child) => convertNodeTreeDates(child));
};
